import { Injectable, inject } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { SnackbarComponent } from './snackbar.component';

@Injectable({ providedIn: 'root' })
export class SnackbarService {
  private snackBar = inject(MatSnackBar);

  warnSnackbar(message: string, config: MatSnackBarConfig<any> = {}) {
    this.openSnackbar(message, {
      panelClass: 'warn-snackbar',
      duration: 0,
      ...config,
    });
  }

  openSnackbar(message: string, config: MatSnackBarConfig<any> = {}) {
    this.snackBar.openFromComponent(SnackbarComponent, {
      ...config,
      data: {
        message,
        hideActions: config?.data?.hideActions ?? false,
        html: config?.data?.html,
      },
      horizontalPosition: 'center',
      verticalPosition: 'top',
      duration: config?.duration ?? 5_000,
    });
  }
}
