<div class="flex items-center">
  <span matSnackBarLabel>{{ snackbarData?.message }}</span>
  <div matSnackBarAction *ngIf="snackbarData?.html">
    <div class="text-guud-white" [innerHTML]="snackbarData.html"></div>
  </div>
  <span *ngIf="!snackbarData?.hideActions" matSnackBarActions>
    <button
      mat-icon-button
      matSnackBarAction
      (click)="snackbarRef.dismissWithAction()"
    >
      <mat-icon class="text-guud-white">close</mat-icon>
    </button>
  </span>
</div>
